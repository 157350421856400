export class ACCESS_ROUTE {
    static readonly HOME = 'HOME';
    static readonly CONTRACT = 'CONTRACT';
    static readonly OPERATION = 'OPERATION';
    static readonly FINANCE_ACCOUNTING = 'FINACC';
    static readonly REPORTS = 'REPORTS';
    static readonly SYSTEM = 'SYSTEM';

    static readonly QUOTATION = 'TQUO';
    static readonly QUOTATION_NEW = 'TQUON';
    static readonly QUOTATION_COPY = 'TQUONC';
    static readonly QUOTATION_NEW_VERSION = 'TQUONV';
    static readonly QUOTATION_VIEW = 'TQUOO';
    static readonly QUOTATION_MODIFY = 'TQUOM';
    static readonly QUOTATION_FINALIZE = 'TQUOF';
    static readonly QUOTATION_START_APPROVAL = 'TQUOST';
    static readonly QUOTATION_APPROVAL = 'TQUOA';
    static readonly QUOTATION_PLACEMENT = 'TQUOPL';
    static readonly QUOTATION_CONFIRM = 'TQUOCF';
    static readonly QUOTATION_REVERSE = 'TQUOR';
    static readonly QUOTATION_CANCEL = 'TQUOX';
    static readonly QUOTATION_DELETED = 'TQUOD';

    static readonly FINANCIAL_REPORT = 'MFINRPT';
    static readonly FINANCIAL_REPORT_DELETE = 'MFINRPTD';
    static readonly FINANCIAL_REPORT_MODIFY = 'MFINRPTM';
    static readonly FINANCIAL_REPORT_NEW = 'MFINRPTN';
    static readonly FINANCIAL_REPORT_VIEW = 'MFINRPTO';

    static readonly TREATY_CONTRACT = 'MTREATY';
    static readonly TREATY_CONTRACT_VIEW = 'MTREATYO';
    static readonly TREATY_CONTRACT_NEW = 'MTREATYN';
    static readonly TREATY_CONTRACT_MODIFY = 'MTREATYM';
    static readonly TREATY_CONTRACT_VALIDATE = 'MTREATYV';
    static readonly TREATY_CONTRACT_START_APPROVAL = 'MTREATYSTA';
    static readonly TREATY_CONTRACT_APPROVAL = 'MTREATYA';
    static readonly TREATY_CONTRACT_TERMINATE = 'MTREATYT';
    static readonly TREATY_CONTRACT_REVERSE = 'MTREATYR';
    static readonly TREATY_CONTRACT_CANCEL = 'MTREATYX';
    static readonly TREATY_CONTRACT_DELETED = 'MTREATYD';
    static readonly TREATY_CONTRACT_FINALIZE = 'MTREATYF';
    static readonly TREATY_CONTRACT_BIND = 'MTREATY';

    static readonly FACILITY_CONTRACT = 'MFACI';
    static readonly FACILITY_CONTRACT_VIEW = 'MFACIO';
    static readonly FACILITY_CONTRACT_NEW = 'MFACIN';
    static readonly FACILITY_CONTRACT_MODIFY = 'MFACIM';
    static readonly FACILITY_CONTRACT_VALIDATE = 'MFACIV';
    static readonly FACILITY_CONTRACT_CANCEL = 'MFACIX';
    static readonly FACILITY_CONTRACT_DELETED = 'MFACID';

    static readonly FACULTATIVE_CONTRACT = 'MFAC';
    static readonly FACULTATIVE_CONTRACT_NEW = 'MFACN';
    static readonly FACULTATIVE_CONTRACT_VIEW = 'MFACO';
    static readonly FACULTATIVE_CONTRACT_MODIFY = 'MFACM';
    static readonly FACULTATIVE_CONTRACT_VALIDATE = 'MFACV';
    static readonly FACULTATIVE_CONTRACT_APPROVAL = 'MFACA';
    static readonly FACULTATIVE_CONTRACT_CANCEL = 'MFACX';
    static readonly FACULTATIVE_CONTRACT_DELETE = 'MFACD';
    static readonly FACULTATIVE_CONTRACT_ISSUE = 'MFACI';
    static readonly FACULTATIVE_CONTRACT_REVERSAL = 'MFACR';

    static readonly CEDING_MENU = 'MCED';
    static readonly CEDING_NEW = 'MCEDN';
    static readonly CEDING_VIEW = 'MCEDO';
    static readonly CEDING_MODIFY = 'MCEDM';
    static readonly CEDING_VALIDATE = 'MCEDV';
    static readonly CEDING_CANCEL = 'MCEDX';
    static readonly CEDING_DELETE = 'MCEDD';
    static readonly CEDING_TERMINATE = 'MCED';

    static readonly REINSURER_MENU = 'MREINS';
    static readonly REINSURER_CREATE_NEW = 'MREINSN';
    static readonly REINSURER_VIEW = 'MREINSO';
    static readonly REINSURER_MODIFY = 'MREINSM';
    static readonly REINSURER_VALIDATE = 'MREINSV';
    static readonly REINSURER_CANCEL = 'MREINSX';
    static readonly REINSURER_DELETED = 'MREINSD';
    static readonly REINSURER_TERMINATE = 'MREINS';

    static readonly AGENT_MENU = 'MAGENT';
    static readonly AGENT_NEW_AGENT = 'MAGENTN';
    static readonly AGENT_VIEW_AGENT = 'MAGENTO';
    static readonly AGENT_MODIFY_AGENT = 'MAGENTM';
    static readonly AGENT_VALIDATE_AGENT = 'MAGENTV';
    static readonly AGENT_CANCEL_AGENT = 'MAGENTX';
    static readonly AGENT_DELETE_AGENT = 'MAGENTD';
    static readonly AGENT_TERMINATE_AGENT = 'MAGENT';

    static readonly CLIENT = 'MCLIENT';
    static readonly CLIENT_NEW_CLIENT = 'MCLIENTN';
    static readonly CLIENT_MODIFY_CLIENT = 'MCLIENTM';
    static readonly CLIENT_VALIDATE_CLIENT = 'MCLIENTV';
    static readonly CLIENT_CANCEL_CLIENT = 'MCLIENTX';
    static readonly CLIENT_VIEW_CLIENT = 'MCLIENTO';
    static readonly CLIENT_DELETED_CLIENT = 'MCLIENTD';
    static readonly CLIENT_TERMINATE_CLIENT = 'MCLIENT';
    static readonly CLIENT_REACTIVATE_CLIENT = 'MCLIENT';

    static readonly REQUEST_ORDER = 'TREQ';
    static readonly REQUEST_ORDER_NEW = 'TREQN';
    static readonly REQUEST_ORDER_MODIFY = 'TREQM';
    static readonly REQUEST_ORDER_VIEW = 'TREQO';
    static readonly REQUEST_ORDER_VALIDATE = 'TREQV';
    static readonly REQUEST_ORDER_CANCEL = 'TREQX';
    static readonly REQUEST_ORDER_DELETED = 'TREQD';
    static readonly REQUEST_ORDER_REVERSE = 'TREQR';

    static readonly WORKSPACE = 'WSPACE';
    static readonly DASHBOARD = 'DASHBOARD';

    static readonly PARTY = 'PARTY';
    static readonly CEDING = 'MCED';
    static readonly REINSURER = 'MREINS';
    static readonly AGENT = 'MAGENT';

    static readonly ORDER_MANAGEMENT = 'ORDER';

    static readonly FINANCE = 'FINANCE';
    static readonly ACCOUNTING = 'ACCOUNTING';
    static readonly LEDGER = 'TLEDG';
    static readonly PERIOD_MAINTENANCE = 'TPERIOD';
    static readonly PERIOD_MAINTENANCE_CLOSE = 'TPERIODCL';
    static readonly PERIOD_MAINTENANCE_OPEN = 'TPERIODOP';

    static readonly BATCH = 'BATCH';

    static readonly PROPOSAL = 'TPRO';
    static readonly PROPOSAL_CREATE_NEW = 'TPRON';
    static readonly PROPOSAL_CREATE_NEW_COPY = 'TPRONC';
    static readonly PROPOSAL_CREATE_NEW_VERSION = 'TPRONV';
    static readonly PROPOSAL_VIEW = 'TPROO';
    static readonly PROPOSAL_MODIFY = 'TPROM';
    static readonly PROPOSAL_FINALIZE = 'TPROF';
    static readonly PROPOSAL_START_BINDING = 'TPROF';
    static readonly PROPOSAL_START_APPROVAL = 'TPROST';
    static readonly PROPOSAL_APPROVAL = 'TPROA';
    static readonly PROPOSAL_PLACEMENT = 'TPROPL';
    static readonly PROPOSAL_CONFIRM = 'TPROCF';
    static readonly PROPOSAL_REVISE = 'TPROR';
    static readonly PROPOSAL_CANCEL = 'TPROX';
    static readonly PROPOSAL_CORRECTION = 'TPROCR';
    static readonly PROPOSAL_DELETE = 'TPROD';

    static readonly COSTING = 'TCOST';
    static readonly COSTING_NEW = 'TCOSTN';
    static readonly COSTING_NEW_COPY = 'TCOSTNC';
    static readonly COSTING_NEW_VERSION = 'TCOSTNV';
    static readonly COSTING_VIEW = 'TCOSTO';
    static readonly COSTING_MODIFY = 'TCOSTM';
    static readonly COSTING_FINALIZED = 'TCOSTF';
    static readonly COSTING_REVERSE = 'TCOSTR';
    static readonly COSTING_CANCEL = 'TCOSTX';
    static readonly COSTING_DELETE = 'TCOSTD';

    static readonly BILLING = 'TBILL';
    static readonly BILLING_VIEW = 'TBILLO';
    static readonly BILLING_NEW = 'TBILLN';
    static readonly BILLING_MODIFY = 'TBILLM';
    static readonly BILLING_VALIDATE = 'TBILLV';
    static readonly BILLING_START_APPROVAL = 'TBILLT';
    static readonly BILLING_APPROVAL = 'TBILLA';
    static readonly BILLING_REVERSE = 'TBILLR';
    static readonly BILLING_CANCEL = 'TBILLX';
    static readonly BILLING_DELETE = 'TBILLD';

    static readonly INVOICE = 'TINVO';
    static readonly INVOICE_CREATE_NEW = 'TINVON';
    static readonly INVOICE_VIEW = 'TINVOO';
    static readonly INVOICE_MODIFY = 'TINVOM';
    static readonly INVOICE_START = 'TINVOST';
    static readonly INVOICE_APPROVAL = 'TINVOA';
    static readonly INVOICE_REVERSE = 'TINVOR';
    static readonly INVOICE_CANCEL = 'TINVOX';
    static readonly INVOICE_DELETE = 'TINVOD';
    static readonly INVOICE_VALIDATE = 'TINVOV';

    static readonly PAYMENT = 'TPAYM';
    static readonly PAYMENT_CREATE_NEW = 'TPAYMN';
    static readonly PAYMENT_VIEW = 'TPAYMO';
    static readonly PAYMENT_MODIFY = 'TPAYMM';
    static readonly PAYMENT_START_APPROVAL = 'TPAYMST';
    static readonly PAYMENT_APPROVAL = 'TPAYMA';
    static readonly PAYMENT_REVERSE = 'TPAYMR';
    static readonly PAYMENT_CANCEL = 'TPAYMX';
    static readonly PAYMENT_DELETE = 'TPAYMD';
    static readonly PAYMENT_VALIDATE = 'TPAYMV';

    static readonly RECEIPT = 'TRECP';
    static readonly RECEIPT_NEW = 'TRECPN';
    static readonly RECEIPT_VIEW = 'TRECPO';
    static readonly RECEIPT_MODIFY = 'TRECPM';
    static readonly RECEIPT_START_APPROVAL = 'TRECPST';
    static readonly RECEIPT_APPROVAL = 'TRECPA';
    static readonly RECEIPT_REVERSE = 'TRECPR';
    static readonly RECEIPT_CANCEL = 'TRECPX';
    static readonly RECEIPT_DELETED = 'TRECPD';

    static readonly JOURNAL = 'TJRNL';
    static readonly JOURNAL_NEW = 'TJRNLN';
    static readonly JOURNAL_VIEW = 'TJRNLO';
    static readonly JOURNAL_MODIFY = 'TJRNLM';
    static readonly JOURNAL_POSTING = 'TJRNLP';
    static readonly JOURNAL_UNPOSTING = 'TJRNLU';
    static readonly JOURNAL_REVERSE = 'TJRNLR';
    static readonly JOURNAL_CANCEL = 'TJRNLX';
    static readonly JOURNAL_DELETED = 'TJRNLD';

    static readonly CHART_OF_ACCOUNT = 'MCOA';
    static readonly CHART_OF_ACCOUNT_NEW = 'MCOAN';
    static readonly CHART_OF_ACCOUNT_VIEW = 'MCOAO';
    static readonly CHART_OF_ACCOUNT_MODIFY = 'MCOAM';
    static readonly CHART_OF_ACCOUNT_DELETED = 'MCOAD';

    static readonly LOB_MAINTENANCE = 'MLOB';
    static readonly LOB_NEW = 'MLOBN';
    static readonly LOB_VIEW = 'MLOBO';
    static readonly LOB_MODIFY = 'MLOBM';
    static readonly LOB_DELETE = 'MLOBD';

    static readonly TOC_MAINTENANCE = 'MTOC';
    static readonly TOC_NEW = 'MTOCN';
    static readonly TOC_VIEW = 'MTOCO';
    static readonly TOC_MODIFY = 'MTOCM';
    static readonly TOC_DELETE = 'MTOCD';

    static readonly TOR_MAINTENANCE = 'MTOR';
    static readonly TOR_NEW = 'MTORN';
    static readonly TOR_VIEW = 'MTORO';
    static readonly TOR_MODIFY = 'MTORM';
    static readonly TOR_DELETE = 'MTORD';

    static readonly TOINFO_MAINTENANCE = 'MINFO';
    static readonly TOINFO_NEW = 'MINFON';
    static readonly TOINFO_VIEW = 'MINFOO';
    static readonly TOINFO_MODIFY = 'MINFOM';
    static readonly TOINFO_DELETE = 'MINFOD';

    static readonly TOI_MAINTENANCE = 'MTOI';
    static readonly TOI_NEW = 'MTOIN';
    static readonly TOI_VIEW = 'MTOIO';
    static readonly TOI_MODIFY = 'MTOIM';
    static readonly TOI_DELETE = 'MTOID';

    static readonly OCCUPATION_MAINTENANCE = 'MOCCUP';
    static readonly OCCUPATION_NEW = 'MOCCUPN';
    static readonly OCCUPATION_VIEW = 'MOCCUPO';
    static readonly OCCUPATION_MODIFY = 'MOCCUPM';
    static readonly OCCUPATION_DELETE = 'MOCCUPD';

    static readonly CURRENCY_MAINTENANCE = 'MCURR';
    static readonly CURRENCY_DELETE = 'MCURRD';
    static readonly CURRENCY_MODIFY = 'MCURRM';
    static readonly CURRENCY_NEW = 'MCURRN';
    static readonly CURRENCY_VIEW = 'MCURRO';

    static readonly TAXES_MAINTENANCE = 'MTAX';
    static readonly TAXES_DELETE = 'MTAXD';
    static readonly TAXES_MODIFY = 'MTAXM';
    static readonly TAXES_NEW = 'MTAXN';
    static readonly TAXES_VIEW = 'MTAXO';

    static readonly CURRENCY_RATE_MAINTENANCE = 'MCRRATE';
    static readonly CURRENCY_RATE_DELETE = 'MCRRATED';
    static readonly CURRENCY_RATE_MODIFY = 'MCRRATEM';
    static readonly CURRENCY_RATE_NEW = 'MCRRATEN';
    static readonly CURRENCY_RATE_VIEW = 'MCRRATEO';

    static readonly BANK_MAINTENANCE = 'MBANK';
    static readonly BANK_NEW = 'MBANKN';
    static readonly BANK_VIEW = 'MBANKO';
    static readonly BANK_MODIFY = 'MBANKM';
    static readonly BANK_DELETE = 'MBANKD';

    static readonly CASH_BANK_MAINTENANCE = 'MCBANK';
    static readonly CASH_BANK_NEW = 'MCBANKN';
    static readonly CASH_BANK_VIEW = 'MCBANKO';
    static readonly CASH_BANK_MODIFY = 'MCBANKM';
    static readonly CASH_BANK_DELETE = 'MCBANKD';

    static readonly FIXED_ASSET_MAINTENANCE = 'MCBANK';
    static readonly FIXED_ASSET_NEW = 'MCBANKN';
    static readonly FIXED_ASSET_VIEW = 'MCBANKO';
    static readonly FIXED_ASSET_MODIFY = 'MCBANKM';
    static readonly FIXED_ASSET_DELETE = 'MCBANKD';

    static readonly ACCOUNTING_RULE_MAINTENANCE = 'MACCRULE';
    static readonly ACCOUNTING_RULE_NEW = 'MACCRULEN';
    static readonly ACCOUNTING_RULE_VIEW = 'MACCRULEO';
    static readonly ACCOUNTING_RULE_MODIFY = 'MACCRULEM';
    static readonly ACCOUNTING_RULE_DELETE = 'MACCRULED';

    static readonly ACCOUNTING_PERIOD_MAINTENANCE = 'MPERIOD';
    static readonly ACCOUNTING_PERIOD_NEW = 'MPERIODN';
    static readonly ACCOUNTING_PERIOD_VIEW = 'MPERIODO';
    static readonly ACCOUNTING_PERIOD_MODIFY = 'MPERIODM';
    static readonly ACCOUNTING_PERIOD_DELETE = 'MPERIODD';

    static readonly DOCUMENT_MAINTENANCE = 'MDOCTYPE';
    static readonly DOCUMENT_NEW = 'MDOCTYPEN';
    static readonly DOCUMENT_VIEW = 'MDOCTYPEO';
    static readonly DOCUMENT_MODIFY = 'MDOCTYPEM';
    static readonly DOCUMENT_DELETE = 'MDOCTYPED';

    static readonly REPORT_MAINTENANCE = 'MREPORT';
    static readonly REPORT_DELETE = 'MREPORTD';
    static readonly REPORT_MODIFY = 'MREPORTM';
    static readonly REPORT_NEW = 'MREPORTN';
    static readonly REPORT_VIEW = 'MREPORTO';

    static readonly TABLE_DEFINITION_MAINTENANCE = 'MTABLE';
    static readonly TABLE_NEW = 'MTABLEN';
    static readonly TABLE_VIEW = 'MTABLEO';
    static readonly TABLE_MODIFY = 'MTABLEM';
    static readonly TABLE_DELETE = 'MTABLED';

    static readonly OBJECT_MAINTENANCE = 'MOBJECT';
    static readonly OBJECT_NEW = 'MOBJECTN';
    static readonly OBJECT_VIEW = 'MOBJECTO';
    static readonly OBJECT_MODIFY = 'MOBJECTM';
    static readonly OBJECT_DELETE = 'MOBJECTD';

    static readonly HOLIDAY_MAINTENANCE = 'MHOLIDAY';
    static readonly HOLIDAY_NEW = 'MHOLIDAYN';
    static readonly HOLIDAY_VIEW = 'MHOLIDAYO';
    static readonly HOLIDAY_MODIFY = 'MHOLIDAYM';
    static readonly HOLIDAY_DELETE = 'MHOLIDAYD';

    static readonly APPROVAL_MATRIX = 'MAPPMATRIX';
    static readonly APPROVAL_MATRIX_DELETE = 'MAPPMATRIXD';
    static readonly APPROVAL_MATRIX_MODIFY = 'MAPPMATRIXM';
    static readonly APPROVAL_MATRIX_NEW = 'MAPPMATRIXN';
    static readonly APPROVAL_MATRIX_VIEW = 'MAPPMATRIXO';

    static readonly TEMPLATE_MAINTENANCE = 'MTEMPLATE';
    static readonly TEMPLATE_DELETE = 'MTEMPLATED';
    static readonly TEMPLATE_MODIFY = 'MTEMPLATEM';
    static readonly TEMPLATE_NEW = 'MTEMPLATEN';
    static readonly TEMPLATE_VIEW = 'MTEMPLATEO';

    static readonly AUTONUMBER_MAINTENANCE = 'MAUTONUM';
    static readonly AUTONUMBER_DELETE = 'MAUTONUMD';
    static readonly AUTONUMBER_MODIFY = 'MAUTONUMM';
    static readonly AUTONUMBER_NEW = 'MAUTONUMN';
    static readonly AUTONUMBER_VIEW = 'MAUTONUMO';

    static readonly SYSTEM_PREFERENCES = 'MPROPS';
    static readonly MODIFY_SETTING = 'MPROPSM';

    static readonly USERS_PARAMETERS = 'MUPRM';
    static readonly USERS_PARAMETERS_NEW = 'MUPRMN';
    static readonly USERS_PARAMETERS_MODIFY = 'MUPRMM';
    static readonly USERS_PARAMETERS_DELETE = 'MUPRMD';

    static readonly SYSTEM_PARAMETERS = 'MSPRM';
    static readonly SYSTEM_PARAMETERS_NEW = 'MSPRMN';
    static readonly SYSTEM_PARAMETERS_VIEW ='MSPRMO';
    static readonly SYSTEM_PARAMETERS_MODIFY = 'MSPRMM';
    static readonly SYSTEM_PARAMETERS_VALIDATE = 'MSPRMV';
    static readonly SYSTEM_PARAMETERS_DELETE = 'MSPRMD';

    static readonly BATCH_SUBMISSION = 'TBATCH';
    static readonly BATCH_SUBMISSION_NEW = 'TBATCHN';
    static readonly BATCH_SUBMISSION_VIEW = 'TBATCHO';
    static readonly BATCH_SUBMISSION_MODIFY = 'TBATCHM';
    static readonly BATCH_SUBMISSION_DELETED = 'TBATCHD';
    static readonly BATCH_SUBMISSION_RUN = 'TBATCHR';

    static readonly SYSTEM_SETTINGS = 'SETTINGS';
    static readonly TABLE_MAINTENANCE = 'MTBL';

    static readonly SYSTEM_SECURITY = 'SECURITY';
    static readonly USER_MAINTENANCE = 'MUSER';
    static readonly NEW_USER = 'MUSERN';
    static readonly VIEW_USER = 'MUSERO';
    static readonly MODIFY_USER = 'MUSERM';
    static readonly ACCESS_RIGHTS_MAINTENANCE = 'MACCESS';
    static readonly ACCESS_RIGHT_NEW = 'MACCESSN';
    static readonly ACCESS_RIGHT_VIEW = 'MACCESSO';
    static readonly ACCESS_RIGHT_MODIFY = 'MACCESSM';
    static readonly REPORTS_SUBMENU = 'REPORTSR';
    static readonly DATA_ANALITICS_SUBMENU = 'REPORTSA';

    static readonly POSTING_RULE = 'MPOSTRULE';
    static readonly POSTING_RULE_NEW = 'MPOSTRULEN';
    static readonly POSTING_RULE_VIEW = 'MPOSTRULEO';
    static readonly POSTING_RULE_MODIFY = 'MPOSTRULEM';
    static readonly POSTING_RULE_DELETE = 'MPOSTRULED';

    static readonly ACCOUNT_DIMENSION = 'MACCDIM';
    static readonly ACCOUNT_DIMENSION_NEW = 'MACCDIMN';
    static readonly ACCOUNT_DIMENSION_VIEW = 'MACCDIMO';
    static readonly ACCOUNT_DIMENSION_MODIFY = 'MACCDIMM';
    static readonly ACCOUNT_DIMENSION_DELETE = 'MACCDIMD';

    static readonly SYSTEM_INFORMATION = "SYSINFO";
}
